const firebase = // process.env.NEXT_PUBLIC_FIREBASE_CONFIG ||
  null;

const defaultFirebase = {
  apiKey: 'AIzaSyA42Nk7F1axhL39Q9Epfk4_2nQGzhd6Xic',
  authDomain: 'luna-9425e.firebaseapp.com',
  databaseURL: 'https://luna-9425e.firebaseio.com',
  projectId: 'luna-9425e',
  storageBucket: 'luna-9425e.appspot.com',
  messagingSenderId: '599718700289',
  appId: '1:599718700289:web:6838ab004d9dda1bade700',
  measurementId: 'G-JQKHQD5YR6',
};

const PROD_ENV = {
  firebase: firebase
    ? (JSON.parse(firebase) as typeof defaultFirebase)
    : defaultFirebase,
  amplitudeKey: '8372f34b039ba866990220def753d9c5',
  segmentKey: 'zYHpUw5fJSV6hD4AQ6KQG6N6PlZwA3VG',
  sentryKey:
    'https://7f22ba88be554add9865661145f1618e@o283181.ingest.sentry.io/1510874',
  baseUrl: 'https://luna.ac',
  graphqlApiUrl:
    //   process.env.NEXT_PUBLIC_GRAPHQL ||
    'https://api.luna.ac/graphql',
  useEmulator: false,
  functionsUrl: 'https://us-central1-luna-9425e.cloudfunctions.net',
  growthBookKey: 'sdk-UfrPtrrrMHtObiGV',
};

const DEV_ENV = {
  useEmulator: true,
  graphqlApiUrl: 'http://localhost:3333/graphql',
  firebase: {
    apiKey: 'AIzaSyA42Nk7F1axhL39Q9Epfk4_2nQGzhd6Xic',
    authDomain: 'luna-9425e.firebaseapp.com',
    databaseURL: 'https://luna-9425e.firebaseio.com',
    projectId: 'luna-9425e',
    storageBucket: 'luna-9425e.appspot.com',
    messagingSenderId: '599718700289',
    appId: '1:599718700289:web:6838ab004d9dda1bade700',
    measurementId: 'G-JQKHQD5YR6',
  },
  amplitudeKey: '8372f34b039ba866990220def753d9c5',
  sentryKey:
    'https://7f22ba88be554add9865661145f1618e@o283181.ingest.sentry.io/1510874',
  baseUrl: 'http://localhost:3000',
  functionsUrl: 'http://localhost:5001/luna-9425e/us-central1',
  growthBookKey: 'sdk-dapSxMHJ0488gRiG',
  segmentKey: 'hlmzFN0DBfTCSpTx6H5hmVHWqc4KnUGv',
};

export const isProduction = process.env.NODE_ENV === 'production';

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return PROD_ENV;

    case 'development':
      return DEV_ENV;

    default:
      return DEV_ENV;
  }
};

export default {
  ...getEnv(),
  google: {
    privateKey: process.env.FIREBASE_PRIVATE_KEY?.replace(
      /\\n/g,
      '\n',
    ) as string,
    clientEmail: process.env.FIREBASE_EMAIL as string,
    projectId: 'luna-9425e',
  },
  airTable: {
    apiKey: process.env.AIRTABLE_API_KEY,
  },
  prismic: {
    releaserToken: process.env.PRISMIC_RELEASER_ACCESS_TOKEN,
  },
  laura: {
    token: process.env.LAURA_TOKEN,
  },
  cpfCnpj: {
    token: process.env.CPF_TOKEN,
  },
};
