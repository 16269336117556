/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
import HideCrisp from '@components/atoms/HideCrisp';
import AmpWrap from '@components/atoms/amp/AmpWrap';
import ExternalScripts from '@components/molecules/ExternalScripts';
import createUrqlClient from '@graphql/urqlClient';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import COLORS from '@lib/constants/colors';
import env from '@lib/constants/env';
import LINKS from '@lib/constants/links';
import gtag from '@lib/helpers/analytics/gtag';
import { saveBrowserData } from '@lib/helpers/browser';
import { partnerConfig } from '@lib/helpers/partner';
import { AuthProvider } from '@lib/hooks/auth';
import { ModalProvider } from '@lib/hooks/modal/ModalProvider';
import { growthbook } from '@lib/services/experiments';
import {
  getSegmentInfo,
  trackEvent,
  trackPageView,
  trackSessionStart,
} from 'lib/services/analytics';
import { LogoJsonLd, SocialProfileJsonLd } from 'next-seo';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import { useEffect } from 'react';
import ReactModal from 'react-modal';

import { Provider as UrqlProvider } from 'urql';

const TransitionGroup = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('react-transition-group').then((mod) => mod.TransitionGroup),
  { ssr: false },
);

export const MyApp = (props: AppProps) => {
  const { Component, pageProps, router } = props;

  useEffect(() => {
    growthbook.loadFeatures();
    getSegmentInfo()
      .then((i) => {
        growthbook.setAttributes({ deviceId: i?.anonymousId, id: i?.userId });
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    if (router.isFallback) return;

    trackSessionStart();
    saveBrowserData();

    Router.events.on('routeChangeComplete', (path) => {
      trackPageView();
      gtag.pageview(path);
    });

    window.addEventListener('beforeinstallprompt', (e) => {
      (e as any).userChoice.then((choiceResult = {}) => {
        trackEvent('add to homescreen', choiceResult);
      });
    });

    window.addEventListener('appinstalled', () => {
      trackEvent('appinstalled');
    });

    ReactModal.setAppElement('#__next');

    if (ReactModal.defaultStyles.overlay && ReactModal.defaultStyles.content) {
      ReactModal.defaultStyles.overlay.backgroundColor = 'rgb(0,0,0,0.5)';

      ReactModal.defaultStyles.content.padding = 32;

      ReactModal.defaultStyles.overlay.zIndex = 9999999999;
    }
  }, [router.isFallback]);

  return (
    <>
      <style jsx global>
        {`
          * {
            box-sizing: border-box;
          }
          html {
            font-family: ${partnerConfig?.font?.body.family ||
            `'Poppins', serif`};
            font-size: 100%;
            color: ${COLORS.black};
          }
          body {
            font-size: 1em;
            font-family: inherit;
            line-height: 1.43;
            overflow-x: hidden;
          }

          .firebase-emulator-warning {
            display: none;
          }

          *,
          :before,
          :after {
            font-family: inherit;
          }

          html,
          body,
          div,
          span,
          applet,
          object,
          iframe,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          pre,
          a,
          abbr,
          acronym,
          address,
          big,
          cite,
          code,
          del,
          dfn,
          em,
          img,
          ins,
          kbd,
          q,
          s,
          samp,
          small,
          strike,
          strong,
          sub,
          sup,
          tt,
          var,
          b,
          u,
          i,
          center,
          dl,
          dt,
          dd,
          ol,
          ul,
          li,
          fieldset,
          form,
          label,
          legend,
          table,
          caption,
          tbody,
          tfoot,
          thead,
          tr,
          th,
          td,
          article,
          aside,
          canvas,
          details,
          embed,
          figure,
          figcaption,
          footer,
          header,
          hgroup,
          menu,
          nav,
          output,
          ruby,
          section,
          summary,
          time,
          mark,
          audio,
          video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            vertical-align: baseline;
            box-sizing: border-box;
          }
          /* HTML5 display-role reset for older browsers */
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }

          input,
          textarea,
          select {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          ol,
          ul {
            list-style: none;
          }
          blockquote,
          q {
            quotes: none;
          }
          blockquote:before,
          blockquote:after,
          q:before,
          q:after {
            content: '';
            content: none;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          *:focus {
            outline: 0;
          }

          button,
          a {
            cursor: pointer;
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          a:hover,
          a:active,
          a:focus {
            outline: none;
          }

          p {
            font-weight: ${partnerConfig?.font?.body.weight || 'lighter'};
            line-height: 1.5;
          }

          li {
            font-weight: ${partnerConfig?.font?.body.weight || 'lighter'};
          }

          hr {
            background: #000;
            border: none;
            height: 1px;
          }

          small {
            display: block;
            font-weight: ${partnerConfig?.font?.body.weight || 'lighter'};
          }

          textarea {
            box-sizing: border-box;
          }

          ::selection {
            background: ${COLORS.pink};
            color: #fff;
          }

          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #bcbcbc;
            opacity: 1; /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #bcbcbc;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #bcbcbc;
          }

          .ReactModal__Overlay {
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${partnerConfig?.colors?.titleColor || COLORS.black};
          }
        `}
      </style>
      <Head>
        <AmpWrap
          nonAmp={
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
            />
          }
        />
        {partnerConfig?.font && partnerConfig.font.loadComponent}
      </Head>
      <ExternalScripts />
      <LogoJsonLd url={env.baseUrl} logo={LINKS.logo} />
      {partnerConfig?.id && <HideCrisp />}

      <SocialProfileJsonLd
        type="Organization"
        name="Luna Seguros"
        url={env.baseUrl}
        sameAs={[
          LINKS.facebook,
          LINKS.linkedin,
          LINKS.twitter,
          LINKS.instagram,
          LINKS.pinterest,
        ]}
      />
      <UrqlProvider value={createUrqlClient()}>
        <GrowthBookProvider growthbook={growthbook}>
          <ModalProvider rootComponent={TransitionGroup}>
            <AuthProvider>
              <Component {...pageProps} />
            </AuthProvider>
          </ModalProvider>
        </GrowthBookProvider>
      </UrqlProvider>
    </>
  );
};

export default MyApp;
