import { useAmp } from 'next/amp';

const AmpWrap = ({
  ampOnly = null,
  nonAmp = null,
}: {
  ampOnly?: JSX.Element | null;
  nonAmp?: JSX.Element | null;
}) => {
  const isAmp = useAmp();

  return (
    <>
      {isAmp && ampOnly}
      {!isAmp && nonAmp}
    </>
  );
};

export default AmpWrap;
