// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://15b77d4caf46452480e5b07aac41b3d5@o283181.ingest.sentry.io/5797766',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    'ChunkLoadError',
    'ServiceWorker',
    /^SecurityError.*$/,
    /^InvalidStateError.*$/,
  ],
  denyUrls: [/localhost:3000/i, /127\.0\.0\.1:3000/i],
});
