"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.integerToCurrency = exports.differencePercentage = exports.getIOF = exports.addFeeToInteger = exports.addPercentage = exports.greaterThanZero = exports.percentageToString = exports.floatToInteger = exports.floatStringToNumber = exports.sum = void 0;
const string_1 = require("./string");
function sum(a, b) {
    return a + b;
}
exports.sum = sum;
function floatStringToNumber(string) {
    return Number((0, string_1.onlyNumbers)(string));
}
exports.floatStringToNumber = floatStringToNumber;
function floatToInteger(number) {
    return Math.round(number * 100);
}
exports.floatToInteger = floatToInteger;
function percentageToString(number) {
    return `${(number * 100).toFixed(1)}%`;
}
exports.percentageToString = percentageToString;
function greaterThanZero(number) {
    return number > 0;
}
exports.greaterThanZero = greaterThanZero;
function addPercentage(number, percentage) {
    return number + number * percentage;
}
exports.addPercentage = addPercentage;
function addFeeToInteger(number, percentage) {
    return Math.round(addPercentage(number, percentage));
}
exports.addFeeToInteger = addFeeToInteger;
function getIOF(liquidPremium, totalPremium) {
    return ((totalPremium * 100) / liquidPremium - 100) / 100;
}
exports.getIOF = getIOF;
function differencePercentage(initial, final) {
    return (final - initial) / initial;
}
exports.differencePercentage = differencePercentage;
const integerToCurrency = (integer, fractionDigits = 2) => `R$ ${(integer / 100).toLocaleString("pt-BR", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
})}`;
exports.integerToCurrency = integerToCurrency;
