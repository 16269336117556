"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxValue = exports.minValue = exports.chunkArray = exports.onlyUnique = void 0;
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
exports.onlyUnique = onlyUnique;
function chunkArray(myArray, chunkSize) {
    const results = [];
    const copy = JSON.parse(JSON.stringify(myArray));
    while (copy.length) {
        results.push(copy.splice(0, chunkSize));
    }
    return results;
}
exports.chunkArray = chunkArray;
function minValue(myArray, attrib) {
    return myArray.reduce((prev, curr) => prev[attrib] < curr[attrib] ? prev : curr);
}
exports.minValue = minValue;
function maxValue(myArray, attrib) {
    return myArray.reduce((prev, curr) => prev[attrib] > curr[attrib] ? prev : curr);
}
exports.maxValue = maxValue;
