/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { User } from 'firebase/auth';

const getSentry = async () => {
  const Sentry = await import('@sentry/nextjs');

  return Sentry;
};

export const configureSentryScope = async (user: User) => {
  const Sentry = await getSentry();

  Sentry.configureScope((scope) => {
    scope.setUser({
      email: user?.email || '',
      id: user?.uid,
      username: user?.displayName || '',
    });
  });
};

export const reportError = async (err: any) => {
  const Sentry = await getSentry();

  console.error(err);
  Sentry.captureException(err);
};
