"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstName = exports.capitalizeFirstLetter = exports.normalizePhoneNumber = exports.prettyJSON = exports.formatPlate = exports.formatCEP = exports.joinWords = exports.insertTextToString = exports.formatCPF = exports.isEmptyString = exports.onlyNumbers = void 0;
const onlyNumbers = (str) => String(str).replace(/[^\d]/g, "");
exports.onlyNumbers = onlyNumbers;
const isEmptyString = (str) => !(str === null || str === void 0 ? void 0 : str.length);
exports.isEmptyString = isEmptyString;
function formatCPF(cpf) {
    return cpf
        .replace(/[^\d]/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}
exports.formatCPF = formatCPF;
function insertTextToString(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
}
exports.insertTextToString = insertTextToString;
function joinWords(words, separator = ", ", lastSeparator = " e ") {
    return words.reduce((text, value, i, array) => text + (i < array.length - 1 ? separator : lastSeparator) + value);
}
exports.joinWords = joinWords;
function formatCEP(str) {
    return `${str.substring(0, 5)}-${str.substring(5, 8)}`;
}
exports.formatCEP = formatCEP;
function formatPlate(str) {
    return `${str.substring(0, 3)}-${str.substring(3, 7)}`;
}
exports.formatPlate = formatPlate;
const prettyJSON = (obj) => JSON.stringify(obj, null, 2);
exports.prettyJSON = prettyJSON;
const normalizePhoneNumber = (phone, options) => {
    const numbers = (0, exports.onlyNumbers)(phone);
    const phoneNumber = numbers.length > 11 && numbers.startsWith("55")
        ? numbers.substring(2)
        : numbers;
    return `${(options === null || options === void 0 ? void 0 : options.removePlus) || (options === null || options === void 0 ? void 0 : options.removeCountryCode) ? "" : "+"}${(options === null || options === void 0 ? void 0 : options.removeCountryCode) ? "" : "55"}${(0, exports.onlyNumbers)(phoneNumber)}`;
};
exports.normalizePhoneNumber = normalizePhoneNumber;
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
const getFirstName = (name) => capitalizeFirstLetter(name.split(" ")[0].toLowerCase());
exports.getFirstName = getFirstName;
