import React from 'react';
import Script from 'next/script';
import { GA_TRACKING_ID } from '@lib/helpers/analytics/gtag';
import AmpWrap from '@components/atoms/amp/AmpWrap';

const ExternalScripts = () => (
  <AmpWrap
    nonAmp={
      <>
        <Script
          strategy="afterInteractive"
          id="chatwoot-script-next"
          dangerouslySetInnerHTML={{
            __html: `
      (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'owLQMAcff5tdAMz7B37HtVQA',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
    `,
          }}
        />
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
        <Script
          strategy="afterInteractive"
          id="gtm-script-next"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </>
    }
  />
);

export default ExternalScripts;
