/* eslint-disable camelcase */
import { getSegmentInfo } from '@lib/services/analytics';
import { isEmptyString } from 'helpers';
import Router from 'next/router';
import { BrowserData } from 'types/dist/browser';
import cookies from './cookies';

function getReferringDomain(referrer: string) {
  if (isEmptyString(referrer)) return null;
  const parts = referrer.split('/');
  if (parts.length >= 3) {
    return parts[2];
  }
  return null;
}

function getReferrerInfo() {
  const { referrer } = document;

  return {
    referrer: isEmptyString(referrer) ? null : referrer,
    referring_domain: getReferringDomain(referrer),
  };
}

function getQueryParamsData() {
  const parameters = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'gclid',
    'fbclid',
  ];

  const search = new URLSearchParams(window.location.search);

  return parameters.reduce<{
    utm_source: null | string;
    utm_medium: null | string;
    utm_campaign: null | string;
    gclid: null | string;
    fbclid: null | string;
  }>(
    (acc, key) => {
      if (search.has(key)) {
        const value = search.get(key);

        return { ...acc, [key]: value };
      }

      return acc;
    },
    {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      fbclid: null,
      gclid: null,
    },
  );
}

export async function saveBrowserData() {
  const data: BrowserData = {
    ...getQueryParamsData(),
    ...getReferrerInfo(),
    router: {
      pathname: Router.pathname,
      asPath: Router.asPath,
    },
    segment: await getSegmentInfo(),
  };

  cookies.setBrowserData(data);
}
