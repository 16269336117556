import { removeEmptyKeysFromObject } from 'helpers';
import { BrowserData } from 'types/dist/browser';
import Cookies from 'js-cookie';
import { UserFromCookie } from 'types/types';
import { isProduction } from '@lib/constants/env';

const api = isProduction
  ? Cookies.withAttributes({ path: '/', domain: '.luna.ac' })
  : Cookies;

export default {
  getUser() {
    const cookie = api.get('auth');
    if (!cookie) {
      return null;
    }
    return JSON.parse(cookie) as UserFromCookie;
  },

  setUser(user: UserFromCookie) {
    api.set('auth', JSON.stringify(user), {
      expires: 1 / 24,
    });
  },

  removeUser() {
    return api.remove('auth');
  },

  setBrowserData(data: BrowserData) {
    const previous = this.getBrowserData();

    const dataToSave = previous
      ? { ...previous, ...removeEmptyKeysFromObject(data) }
      : data;

    api.set('luna_c', btoa(JSON.stringify(dataToSave)), {
      expires: 1 / 24,
    });
  },
  getBrowserData() {
    const cookie = api.get('luna_c');

    if (!cookie) {
      return null;
    }
    return JSON.parse(atob(cookie)) as BrowserData;
  },
};
