/* eslint-disable no-unused-expressions */

import { Maybe } from '@graphql/generated';
import { trackEvent } from '@lib/services/analytics';
import { User } from 'firebase/auth';
import { normalizePhoneNumber } from './misc';

export const openHelpDeskChat = () => {
  trackEvent('opened help desk chat');
  window.$chatwoot?.toggle('open');
};

export const registerUserOnHelpDesk = (user: User) => {
  window.$chatwoot?.setUser(user.uid, {
    email: user.email,
    name: user.displayName,
    avatar_url: user.photoURL,
  });
};

export const registerQuoteDataOnHelpDesk = (quote: {
  email?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  conductor?: { name?: Maybe<string> } | null;
  userId?: Maybe<string>;
  id: string;
}) => {
  if (quote.userId) {
    window.$chatwoot?.setUser(quote.userId, {
      email: quote.email,
      name: quote.conductor?.name,
      phone_number: quote.phoneNumber
        ? normalizePhoneNumber(quote.phoneNumber)
        : null,
    });
  }
};
