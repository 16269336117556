/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */

import { Question } from 'types/types';

export const notNull = (arg: unknown) => arg !== undefined && arg !== null;

export const isNull = (arg: unknown) => !notNull(arg);

export const timeout = (ms: number) =>
  new Promise((res) => setTimeout(res, ms));

export const getToken = (authorization: string) =>
  authorization?.replace('Bearer ', '')?.replace('Basic ', '');

export const prettyJSON = (obj: any) => JSON.stringify(obj, null, 2);

export const codify = (string: any) => `\`\`\`${string}\`\`\``;

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log('Async: Copying to clipboard was successful!');
    },
    (err) => {
      console.error('Async: Could not copy text: ', err);
    },
  );
}

export default function mergeRefs(refs: any[]) {
  return (value: any) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    });
  };
}

export function percentage(partialValue: number, totalValue: number) {
  return (100 * partialValue) / totalValue;
}

export const hasWindow = () => typeof window !== 'undefined';

export const normalizeFloatToInteger = (n: number) => Math.ceil(n * 100);

export const currencyToNumber = (currency: string) => {
  const number = Number(
    currency
      .replace('.', '')
      .replace(',', '.')
      .replace(/[^0-9.-]+/g, ''),
  );

  return number;
};

export function isValidDate(d: Date) {
  try {
    return d instanceof Date && !Number.isNaN(d.getDate());
  } catch (error) {
    return false;
  }
}

export const integerToCurrency = (integer: number, fractionDigits = 2) =>
  `R$ ${(integer / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })}`;

export const normalizeIndex = (str: string | string[]) => {
  try {
    const number = Number(str);

    if (Number.isNaN(number)) throw Error();

    if (number < 1) throw Error();

    return number;
  } catch (error) {
    return 0;
  }
};

export const cleanEmptyKeys = (obj: Record<string, any>) => {
  const cleaned = Object.entries(obj).reduce(
    (acc, [key, value]) => (notNull(value) ? { ...acc, [key]: value } : acc),
    {},
  );

  return cleaned;
};

export const getObjPropertyByPath = ({
  path,
  obj,
}: {
  path: string;
  obj: Record<string, any>;
}) => path.split('.').reduce((p, c) => p?.[c], obj);

export const getFilledRequiredFieldsLength = (
  obj: Record<string, any> | undefined | null,
  requiredFields: string[],
) => {
  if (obj) {
    const count = requiredFields.reduce((acc, path) => {
      if (notNull(getObjPropertyByPath({ path, obj }))) return acc + 1;

      return acc;
    }, 0);

    return count;
  }

  return requiredFields.length;
};

export const getFirstName = (name: string) => name.split(' ')[0];

export const onlyNumbers = (str: string | number) =>
  String(str).replace(/[^\d]/g, '');

export const isValidPhone = (phone: string) =>
  !!onlyNumbers(phone).match(/^(?:(55\d{2})|\d{2})[6-9]\d{8}$/gm);

export function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getActualQuestion<T>({
  questions,
  currentQuestionIndex,
  requiredFields,
  item,
}: {
  questions: Question[];
  currentQuestionIndex: number;
  requiredFields: (keyof T | string)[];
  item: T;
}): Question | null {
  return questions.slice(
    0,
    getFilledRequiredFieldsLength(item as any, requiredFields as string[]) + 1,
  )[currentQuestionIndex - 1];
}

export function getActualQuestionV2<T>({
  questions,
  currentId,
  requiredFields,
  item,
}: {
  questions: Question[];
  currentId: string;
  requiredFields: (keyof T | string)[];
  item: T;
}): Question | null {
  return questions.slice(
    0,
    getFilledRequiredFieldsLength(item as any, requiredFields as string[]) + 1,
  )[questions.findIndex((q) => q.id === currentId)];
}

export const normalizePhoneNumber = (
  phone: string,
  options?: { removePlus: boolean },
) => `${options?.removePlus ? '' : '+'}55${onlyNumbers(phone)}`;

export function groupBy<K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K,
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();

  list.forEach((item) => {
    const key = keyGetter(item);

    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
}

export function inIframe() {
  if (typeof window === 'undefined') return false;

  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init);
  return res.json();
}
