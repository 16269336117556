import { registerUserOnHelpDesk } from '@lib/helpers/chat';
import cookies from '@lib/helpers/cookies';
import { growthbook } from '@lib/services/experiments';
import { getFirebaseAuth } from '@lib/services/firebase';
import { User } from 'firebase/auth';
import { setUserIdentity } from 'lib/services/analytics';
import { configureSentryScope } from 'lib/services/sentry';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserFromCookie } from 'types/types';

async function mapUserData(user: User) {
  const token = await user.getIdToken();

  return {
    ...user.toJSON(),

    token,
  } as UserFromCookie;
}

interface AuthComponent {
  user: UserFromCookie | null;
  loading: boolean;
}

const defaultContext: AuthComponent = {
  user: null,
  loading: true,
};

const context = createContext(defaultContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserFromCookie | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFirebaseAuth().then((auth) => {
      auth.onIdTokenChanged(async (u) => {
        const userData = u ? await mapUserData(u) : null;

        setUser(userData);
        setLoading(false);

        if (u && userData) {
          cookies.setUser(userData);

          setUserIdentity(u);
          configureSentryScope(u);
          registerUserOnHelpDesk(u);
          growthbook.setAttributes({
            id: userData.uid,
          });
        } else {
          cookies.removeUser();
        }
      });
    });
  }, []);

  return (
    <context.Provider value={{ user, loading }}>{children}</context.Provider>
  );
};

export const useUser = () => {
  const state = useContext(context);

  return state;
};
