// eslint-disable-next-line import/no-cycle
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  Firestore,
} from 'firebase/firestore';
import env from 'lib/constants/env';

let firebaseApp: FirebaseApp | null = null;

export const getFirebaseApp = () => {
  if (firebaseApp) return firebaseApp;

  firebaseApp = initializeApp(env.firebase);

  return firebaseApp;

  // if (firebase.apps.length > 0) return firebase;
  // firebase.initializeApp(env.firebase);

  // return firebase;
};

let db: Firestore | null = null;

export const getFirebaseFirestore = () => {
  if (db) return db;

  const app = getFirebaseApp();

  db = getFirestore(app);

  if (env.useEmulator) {
    connectFirestoreEmulator(db, window.location.hostname, 8080);
  }

  return db;
};

let auth: Auth | null = null;

export const getFirebaseAuth = async () => {
  if (auth) return auth;

  const firebase = getFirebaseApp();

  const { getAuth } = await import('firebase/auth');

  auth = getAuth(firebase);

  auth.languageCode = 'pt';

  if (env.useEmulator) {
    const { connectAuthEmulator } = await import('firebase/auth');
    connectAuthEmulator(auth, `http://${window.location.hostname}:9099/`);
    auth.settings.appVerificationDisabledForTesting = true;
  }

  return auth;
};
