import { GrowthBook } from '@growthbook/growthbook-react';
import env from '@lib/constants/env';
import { trackEvent } from './analytics';

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: env.growthBookKey,
  trackingCallback: (experiment, result) => {
    trackEvent('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});
