/* eslint-disable no-console */
import { User } from 'firebase/auth';
import env from 'lib/constants/env';
import Router from 'next/router';

import { partnerConfig } from '@lib/helpers/partner';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { BrowserData } from 'types/dist/browser';

const analytics = AnalyticsBrowser.load({
  writeKey: env.segmentKey,
});

export const trackEvent = (
  event: string,
  properties?: Record<string, unknown>,
) => {
  const propertiesWithPathname = {
    ...properties,
    pathname: Router.pathname,
    query: Router.query,
    partner: partnerConfig?.id,
  };

  analytics.track(event, propertiesWithPathname).catch(console.error);
};

export const trackPageView = () => {
  analytics.page({
    pathname: Router.pathname,
    query: Router.query,
    partner: partnerConfig?.id,
  });
};

export const trackSessionStart = () => {
  analytics.page('session start', {
    pathname: Router.pathname,
    query: Router.query,
    partner: partnerConfig?.id,
  });
};

export const setUserIdentity = (user: User) => {
  analytics.identify(user.uid);
};

export const trackGetQuoteClick = (from: string) =>
  trackEvent('click on get quote button', { from });

export async function getSegmentInfo(): Promise<BrowserData['segment']> {
  try {
    const user = await analytics.user();

    return {
      userId: (user.id() as string) || null,
      anonymousId: (user.anonymousId() as string) || null,
    };
  } catch {
    return null;
  }
}
