import { createGlobalStyle } from 'styled-components';
import AmpWrap from './amp/AmpWrap';

const Global = createGlobalStyle`
.woot-widget-holder, .woot--bubble-holder {
  display: none !important;
}
`;

const HideCrisp = () => <AmpWrap nonAmp={<Global />} />;

export default HideCrisp;
