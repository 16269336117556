/* eslint-disable @next/next/no-page-custom-font */
import { PartnerID } from 'partnerships';
import { staticCss, staticImageFile } from './publicFiles';

const subdomain =
  typeof window === 'undefined' ? null : window.location.host.split('.')[0];

interface PartnerConfig {
  id: PartnerID;
  colors?: {
    brandColor: string;
    black: string;
    white: string;
    buttonColor: string;
    buttonFontColor?: string;
    titleColor?: string;
  };
  images: {
    logo: {
      url: string;
      height: number;
    };
  };
  font?: {
    loadComponent: React.ReactNode;
    heading: {
      family: string;
      weight?: number;
    };
    body: {
      family: string;
      weight: number;
    };
  };
  config?: {
    roundedButtons?: boolean;
    askForContactFirst?: boolean;
  };
}

const partnerMap: Record<PartnerID, PartnerConfig> = {
  btg: {
    colors: {
      brandColor: '#195ab4',
      black: '#05132a',
      white: '#ffffff',
      buttonColor: '#195ab4',
    },
    images: {
      logo: {
        url: staticImageFile('btg.svg'),
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <style>{`
@font-face {
    font-family: moderat-medium;
    src: url(/static/fonts/Moderat-Medium.otf);
    font-display: swap
}

@font-face {
    font-family: moderat-regular;
    src: url(/static/fonts/Moderat-Regular.otf);
    font-display: swap
}

`}</style>
      ),
      body: {
        family: `"moderat-regular",Helvetica,sans-serif;`,
        weight: 400,
      },
      heading: {
        family: `"moderat-medium",Helvetica,sans-serif;`,
      },
    },
    id: 'btg',
  },
  picpay: {
    colors: {
      brandColor: '#238662',
      black: '#373737',
      white: '#ffffff',
      buttonColor: '#238662',
      buttonFontColor: '#f5f5f5',
    },
    images: {
      logo: {
        url: staticImageFile('picpay.svg'),
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
          />
          <style>{`

@font-face {
    font-family: BRSonoma;
    src: url(/static/fonts/BRSonoma-Bold.woff2) format("woff2"),url(/static/fonts/BRSonoma-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}
`}</style>
        </>
      ),
      body: {
        family: 'Inter, sans-serif',
        weight: 400,
      },
      heading: {
        family: 'BRSonoma, serif',
      },
    },
    id: 'picpay',
  },
  justos: {
    colors: {
      brandColor: '#3b6aff',
      black: '#32373e',
      white: '#ffffff',
      buttonColor: '#3b6aff',
    },
    images: {
      logo: {
        url: 'https://orcamento.justos.com.br/assets/Logo.e827804c.svg',
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Sora:wght@800&display=swap"
          />
        </>
      ),
      body: {
        family: 'Inter,sans-serif',
        weight: 400,
      },
      heading: {
        family: 'Sora,sans-serif',
      },
    },
    id: 'justos',
  },
  idinheiro: {
    colors: {
      brandColor: '#0071ff',
      black: '#373737',
      white: '#ffffff',
      buttonColor: '#0071ff',
    },
    images: {
      logo: {
        url: staticImageFile('idinheiro.svg'),
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <style>{`
@font-face {
  font-family: "Overpass";
  src: local('Overpass Regular'), local('Overpass-Regular'), url('/static/fonts/Overpass-Regular.woff2') format('woff2'), url('/static/fonts/Overpass-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overpass";
  src: local('Overpass SemiBold'), local('Overpass-SemiBold'), url('/static/fonts/Overpass-SemiBold.woff2') format('woff2'), url('/static/fonts/Overpass-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overpass";
  src: local('Overpass Bold'), local('Overpass-Bold'), url('/static/fonts/Overpass-Bold.woff2') format('woff2'), url('/static/fonts/Overpass-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
`}</style>
      ),
      body: {
        family: 'Overpass,Trebuchet MS,Helvetica,Arial,sans-serif',
        weight: 400,
      },
      heading: {
        family: 'Overpass,Trebuchet MS,Helvetica,Arial,sans-serif',
      },
    },
    id: 'idinheiro',
  },
  kavak: {
    colors: {
      brandColor: '#3374db',
      black: '#000',
      white: '#ffffff',
      buttonColor: '#3374db',
    },
    images: {
      logo: {
        url: 'https://upload.wikimedia.org/wikipedia/commons/9/9e/Logo_de_Kavak.svg',
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <link rel="stylesheet" href={staticCss('kavak-fonts.css')} />
      ),
      body: {
        family:
          '"vazirmatn","Roboto","-apple-system","BlinkMacSystemFont","Segoe UI","Helvetica Neue","Arial","Noto Sans","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',

        weight: 400,
      },
      heading: {
        family:
          '"readexpro","nokasemibold","Roboto","-apple-system","BlinkMacSystemFont","Segoe UI","Helvetica Neue","Arial","Noto Sans","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      },
    },
    id: 'kavak',
  },
  sicredi: {
    colors: {
      brandColor: '#64c832',
      black: '#323C32',
      white: '#ffffff',
      buttonColor: '#3FA110',
    },
    images: {
      logo: {
        url: 'https://www.sicredi.com.br/static/home/assets/header/logo-svg2.svg',
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Exo+2:wght@500&display=swap"
          />
        </>
      ),
      body: {
        family: '"Nunito",sans-serif',
        weight: 400,
      },
      heading: {
        family: '"Exo 2",sans-serif',
      },
    },
    id: 'sicredi',
  },
  localiza: {
    colors: {
      brandColor: '#004521',
      black: '#323C32',
      white: '#ffffff',
      buttonColor: '#78de1f',
    },
    images: {
      logo: {
        url: staticImageFile('localiza.png'),
        height: 35,
      },
    },

    font: {
      loadComponent: (
        <link rel="stylesheet" href={staticCss('localiza-fonts.css')} />
      ),
      body: {
        family:
          'LocalizaDinDisplay_Regular,Helvetica Neue,Helvetica,Arial,sans-serif',

        weight: 400,
      },
      heading: {
        family:
          'LocalizaDinDisplay_Bold,Helvetica Neue,Helvetica,Arial,sans-serif',
      },
    },
    id: 'localiza',
  },
  abasteceai: {
    id: 'abasteceai',
    images: {
      logo: {
        url: staticImageFile('logo-abastece-ai.png'),
        height: 50,
      },
    },
    colors: {
      black: '#000000',
      brandColor: '#5982ff',
      buttonColor: '#ffd100',
      white: '#ffffff',
      buttonFontColor: '#000000',
    },
    font: {
      loadComponent: (
        <style>{`
            @font-face {
                font-display: block;
                font-family: 'hendersonsans';
                font-weight: 200;
                src: url('/static/fonts/hendersonsansbasic-light-webfont.woff2');
            }

            /*!sc*/
            @font-face {
                font-display: block;
                font-family: 'hendersonsans';
                font-weight: 400;
                src: url('/static/fonts/hendersonsansbasic-regular-webfont.woff2');
            }

            /*!sc*/
            @font-face {
                font-display: block;
                font-family: 'hendersonsans';
                font-weight: 600;
                src: url('/static/fonts/hendersonsansbasic-bold-webfont.woff2');
            }`}</style>
      ),
      body: {
        family: '"hendersonsans",sans-serif',

        weight: 400,
      },
      heading: {
        family: '"hendersonsans",sans-serif',
      },
    },
  },
  premmia: {
    colors: {
      brandColor: '#008542',
      black: '#000000',
      white: '#ffffff',
      buttonColor: '#008542',
    },
    images: {
      logo: {
        url: staticImageFile('logo-premmia.png'),
        height: 45,
      },
    },
    font: {
      loadComponent: (
        <style>{`
          @font-face {
                font-family: "Petrobras Sans Extra Bold";
                src: url("/static/fonts/PetrobrasSans_XBd.ttf") format("truetype");
            }

            @font-face {
                font-family: "Petrobras Sans RG";
                src: url("/static/fonts/PetrobrasSans_Rg_1.ttf") format("truetype");
            }

            @font-face {
                font-family: "Petrobras Sans";
                src: url("/static/fonts/PetrobrasSans_Rg.ttf") format("truetype");
            }
`}</style>
      ),
      body: {
        family: `'Petrobras Sans RG', Arial, sans-serif;`,

        weight: 400,
      },
      heading: {
        family: `'Petrobras Sans Extra Bold', Arial, sans-serif`,
      },
    },
    id: 'premmia',
  },
  xp: {
    colors: {
      brandColor: '#1f1f1f',
      black: '#1f1f1f',
      white: '#ffffff',
      buttonColor: '#fbc105',
      buttonFontColor: '#1f1f1f',
    },
    images: {
      logo: {
        url: staticImageFile('logo-xp.png'),
        height: 45,
      },
    },
    font: {
      loadComponent: (
        <style>{`
@font-face {
  font-family: "XP Lighthouse";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/static/fonts/XP-LightLighthouse.otf");
}

@font-face {
  font-family: "XP Lighthouse";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/static/fonts/XP-RegularLighthouse.otf");
}

@font-face {
  font-family: "XP Lighthouse";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/static/fonts/XP-MediumLighthouse.otf");
}

@font-face {
  font-family: "XP Lighthouse";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/static/fonts/XP-BoldLighthouse.otf");
}
`}</style>
      ),
      body: {
        family: `'XP Lighthouse', Arial, sans-serif;`,

        weight: 400,
      },
      heading: {
        family: `'XP Lighthouse', Arial, sans-serif`,
      },
    },
    id: 'xp',
  },
  movida: {
    colors: {
      brandColor: '#f97d00',
      black: '#000',
      white: '#ffffff',
      buttonColor: '#f97d00',
    },
    images: {
      logo: {
        url: staticImageFile('movida-logo.png'),
        height: 35,
      },
    },
    font: {
      loadComponent: (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@700&display=swap"
            rel="stylesheet"
          />
        </>
      ),
      body: {
        family: `Montserrat,Roboto,Helvetica Neue,sans-serif`,

        weight: 400,
      },
      heading: {
        family: `Roboto,Helvetica Neue,sans-serif`,
      },
    },
    id: 'movida',
  },
  unidas: {
    id: 'unidas',

    images: {
      logo: {
        height: 50,
        url: staticImageFile('logo-unidas.png'),
      },
    },
    colors: {
      brandColor: '#016ea7',
      black: '#000000',
      white: '#ffffff',
      buttonColor: '#00AADB',
    },
    font: {
      loadComponent: (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
          />
        </>
      ),
      heading: { family: 'Roboto,Helvetica Neue,sans-serif' },
      body: { family: 'Roboto,Helvetica Neue,sans-serif', weight: 400 },
    },
  },
  shopcar: {
    id: 'shopcar',

    images: {
      logo: {
        height: 50,
        url: staticImageFile('shopcar-logo.png'),
      },
    },
    colors: {
      brandColor: '#b01717',
      black: '#000000',
      white: '#ffffff',
      buttonColor: '#b01717',
    },
    font: {
      loadComponent: (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
          />
        </>
      ),
      heading: { family: 'Roboto,Helvetica Neue,sans-serif' },
      body: { family: 'Roboto,Helvetica Neue,sans-serif', weight: 400 },
    },
  },
  unico: {
    id: 'unico',
    images: {
      logo: {
        height: 27,
        url: staticImageFile('luna.svg'),
      },
    },
    colors: {
      buttonColor: '#1172EB',
      brandColor: '#1172EB',
      black: '#112035',
      white: '#ffffff',
    },
    config: {
      roundedButtons: true,
      askForContactFirst: true,
    },
  },
  olhonocarro: {
    id: 'olhonocarro',
    images: {
      logo: {
        height: 50,
        url: staticImageFile('olhonocarro.svg'),
      },
    },
    colors: {
      black: '#404040',
      brandColor: '#3cc84b',
      buttonColor: '#2659f3',
      white: '#ffffff',
    },
    font: {
      loadComponent: (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
          />
        </>
      ),
      body: {
        family: 'Inter,Roboto,Helvetica Neue,sans-serif',
        weight: 400,
      },
      heading: {
        family: 'Inter,Roboto,Helvetica Neue,sans-serif',
        weight: 500,
      },
    },
  },
  zipia: {
    id: 'zipia',
    images: {
      logo: {
        height: 50,
        url: staticImageFile('luna.svg'),
      },
    },
  },
};

export const partnerConfig: PartnerConfig | null =
  partnerMap[subdomain as PartnerID] || null;
