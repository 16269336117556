import { partnerConfig } from '@lib/helpers/partner';

const COLORS = {
  black: partnerConfig?.colors?.black || '#112035',
  lightGray: '#eaeaea',
  gray: '#717171',
  pink: partnerConfig?.colors?.brandColor || '#FF1464',
  aqua: '#1d7a87',
  pastel: partnerConfig?.colors?.white || '#faf3ee',
  smoothGray: '#f0f6ff',
};

export default COLORS;
