"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateDiffInDays = exports.timeout = exports.getNextOpeningHour = exports.isWorkingHour = exports.isDayOff = exports.holidays = exports.dayOffs = exports.isWeekend = exports.MONTHS = exports.changeMinutesOnDate = exports.getAge = exports.changeDaysOnDate = exports.ptBrFormatToDate = exports.dateToSuhaiFormat = exports.formatDateOnlyDayAndMonth = exports.formatDatePtBr = exports.unixTimestampToDate = exports.dateToUnixTimestamp = void 0;
const dateToUnixTimestamp = (date) => Math.floor(date.getTime() / 1000);
exports.dateToUnixTimestamp = dateToUnixTimestamp;
const unixTimestampToDate = (number) => new Date(number * 1000);
exports.unixTimestampToDate = unixTimestampToDate;
function formatDatePtBr(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}
exports.formatDatePtBr = formatDatePtBr;
function formatDateOnlyDayAndMonth(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${day}/${month}`;
}
exports.formatDateOnlyDayAndMonth = formatDateOnlyDayAndMonth;
function dateToSuhaiFormat(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}
exports.dateToSuhaiFormat = dateToSuhaiFormat;
function ptBrFormatToDate(date) {
    const [day, month, year] = date.split("/").map(Number);
    return new Date(year, month - 1, day);
}
exports.ptBrFormatToDate = ptBrFormatToDate;
function changeDaysOnDate(date, days) {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() + days);
    return d;
}
exports.changeDaysOnDate = changeDaysOnDate;
function getAge(d1) {
    const d2 = new Date();
    const diff = d2.getTime() - d1.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}
exports.getAge = getAge;
function changeMinutesOnDate(date, minutes) {
    const d = new Date(date.getTime());
    d.setMinutes(d.getMinutes() + minutes);
    return d;
}
exports.changeMinutesOnDate = changeMinutesOnDate;
exports.MONTHS = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];
function isWeekend(date = new Date()) {
    return date.getDay() === 6 || date.getDay() === 0;
}
exports.isWeekend = isWeekend;
exports.dayOffs = [
    { day: 23, month: 12, year: 2022, name: "Folga natal" },
    { day: 30, month: 12, year: 2022, name: "Folga ano novo" },
    { day: 2, month: 1, year: 2023, name: "Folga ano novo" },
    { day: 20, month: 2, year: 2023, name: "Segunda Carnaval" },
    { day: 21, month: 2, year: 2023, name: "Terça Carnaval" },
    { day: 27, month: 3, year: 2023, name: "Offsite Justos" },
    { day: 28, month: 3, year: 2023, name: "Offsite Justos" },
    { day: 29, month: 3, year: 2023, name: "Offsite Justos" },
    { day: 30, month: 3, year: 2023, name: "Offsite Justos" },
    { day: 9, month: 6, year: 2023, name: "Emenda Feriado" },
    { day: 8, month: 9, year: 2023, name: "Emenda Feriado" },
    { day: 12, month: 2, year: 2024, name: "Carnaval" },
    { day: 13, month: 2, year: 2024, name: "Carnaval" },
    { day: 29, month: 3, year: 2024, name: "Sexta-feira Santa" },
    { day: 6, month: 5, year: 2024, name: "Offsite Justos" },
    { day: 7, month: 5, year: 2024, name: "Offsite Justos" },
    { day: 8, month: 5, year: 2024, name: "Offsite Justos" },
    { day: 9, month: 5, year: 2024, name: "Offsite Justos" },
    { day: 10, month: 5, year: 2024, name: "Offsite Justos" },
    { day: 30, month: 5, year: 2024, name: "Corpus Christi" },
    { day: 9, month: 7, year: 2024, name: "Revolução Constitucionalista" },
];
exports.holidays = [
    { day: 1, month: 1, name: "Ano Novo" },
    { day: 25, month: 1, name: "Aniversário de São Paulo" },
    { day: 21, month: 4, name: "Tiradentes" },
    { day: 1, month: 5, name: "Dia do trabalho" },
    {
        day: 8,
        month: 6,
        name: "Corpus Christi",
    },
    { day: 7, month: 9, name: "Independência do Brasil" },
    { day: 12, month: 10, name: "Dia das Crianças" },
    { day: 2, month: 11, name: "Finados" },
    { day: 15, month: 11, name: "Proclamação da República" },
    { day: 20, month: 11, name: "Consciência Negra" },
    { day: 25, month: 12, name: "Natal" },
    { day: 31, month: 12, name: "Virado do Ano" },
];
function isDayOff(date = new Date()) {
    const dates = [...exports.holidays, ...exports.dayOffs];
    return dates.some((holiday) => holiday.day === date.getDate() &&
        holiday.month === date.getMonth() + 1 &&
        (!holiday.year || holiday.year === date.getFullYear()));
}
exports.isDayOff = isDayOff;
const FIRST_HOUR = 9;
const LAST_HOUR = 18;
function isWorkingHour(now = new Date(), timezoneDifference = -3) {
    return (!isWeekend(now) &&
        !isDayOff(now) &&
        now.getHours() >= FIRST_HOUR - timezoneDifference &&
        now.getHours() < LAST_HOUR - timezoneDifference);
}
exports.isWorkingHour = isWorkingHour;
function getNextOpeningHour(date, timezoneDifference = -3) {
    if (isWorkingHour(date, timezoneDifference))
        return date;
    const d = new Date(date.getTime());
    if (d.getHours() >= FIRST_HOUR - timezoneDifference) {
        d.setDate(d.getDate() + 1);
    }
    d.setHours(FIRST_HOUR - timezoneDifference, 0, 0);
    return getNextOpeningHour(d, timezoneDifference);
}
exports.getNextOpeningHour = getNextOpeningHour;
const timeout = (ms) => new Promise((res) => setTimeout(res, ms));
exports.timeout = timeout;
function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
exports.dateDiffInDays = dateDiffInDays;
