"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeUndefinedFromJson = exports.removeEmptyKeysFromObject = exports.renameKeys = void 0;
function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}
exports.renameKeys = renameKeys;
function removeEmptyKeysFromObject(object) {
    return Object.keys(object)
        .filter((k) => object[k] != null)
        .reduce((a, k) => (Object.assign(Object.assign({}, a), { [k]: object[k] })), {});
}
exports.removeEmptyKeysFromObject = removeEmptyKeysFromObject;
function removeUndefinedFromJson(obj) {
    return JSON.parse(JSON.stringify(obj));
}
exports.removeUndefinedFromJson = removeUndefinedFromJson;
