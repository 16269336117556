
export const GA_TRACKING_ID = 'GTM-N4G7VHW';


// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageview = (url: string) => {
  window?.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category: string;
  label: string;
  value: string;
}) => {
  window?.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export default {
  event,
  pageview,
};
