import cookies from '@lib/helpers/cookies';
import { cacheExchange } from '@urql/exchange-graphcache';
import fetch from 'isomorphic-unfetch';
import env from 'lib/constants/env';
import { Client, dedupExchange, fetchExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { makeOperation } from '@urql/core';
import { getFirebaseAuth } from '@lib/services/firebase';
import { Conductor } from './generated';

const cache = cacheExchange({
  keys: {
    Coverage: () => null,
    Installment: () => null,
    Conductor: (data) => (data as Conductor)?.cpf || null,
    VehicleVersion: () => null,
    OfferPrice: () => null,
    OfferAssistance: () => null,
  },
});

export default function createUrqlClient() {
  const client = new Client({
    url: env.graphqlApiUrl,
    fetch,
    fetchOptions: () => ({
      credentials: env.graphqlApiUrl.match('luna.ac') ? 'include' : 'omit',
      headers: {
        'x-origin': 'web',
      },
    }),
    exchanges: [
      dedupExchange,
      cache,
      authExchange<{ token?: string }>({
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState || !authState.token) {
            return operation;
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
              },
            },
          });
        },

        didAuthError: ({ error }) =>
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)

          error.graphQLErrors.some((e) => e.message.match('não autorizado')),
        willAuthError: () => {
          const now = new Date().getTime();

          const expirationTime =
            cookies.getUser()?.stsTokenManager.expirationTime;

          if (expirationTime && now > expirationTime - 1000 * 60) return true;

          return false;
        },
        getAuth: async ({ authState }) => {
          const token = cookies.getUser()?.token;

          if (!authState) {
            if (token) return { token };

            return null;
          }

          const auth = await getFirebaseAuth();

          const refreshedToken = await auth.currentUser?.getIdToken(true);

          if (refreshedToken) {
            return {
              token: refreshedToken,
            };
          }

          return null;
        },
      }),
      fetchExchange,
    ],
  });

  return client;
}
