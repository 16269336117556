const LINKS = {
  instagram: 'https://www.instagram.com/oilunabr/',
  twitter: 'https://twitter.com/oilunabr',
  linkedin: 'https://www.linkedin.com/company/oiluna',
  facebook: 'https://www.facebook.com/oilunabr',
  help: 'https://ajuda.luna.ac/',
  blog: 'https://medium.com/oiluna',
  deepLinkApp: 'https://oiluna.page.link/app',
  favIcon: '/static/images/icon-512x512.png',
  logo: 'https://luna.ac/static/images/luna-logo.png',
  ogImage: 'https://luna.ac/static/images/social.jpeg',
  whatsApp: 'https://wa.me/551137268225',
  pinterest: 'https://www.pinterest.com/lunaseguros',
  google: 'https://g.page/oiluna',
  carToolAirtable: 'https://materiais.luna.ac/consultoria-gratuita',
  appStore:
    'https://apps.apple.com/br/app/luna-ipva-multas-seguro/id1614491886',
  googlePlay: 'https://play.google.com/store/apps/details?id=ac.luna.customer',
  downloadApp: 'http://onelink.to/luna',
};

export default LINKS;
